import React, { useState, useEffect } from "react";
import device from "current-device";
import { useParams, useHistory } from "react-router-dom";
import { isUUID } from "validator";

import {
  Button,
  Snackbar,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";

import Loader from "../components/Loader";
import FormElement from "../components/FormElement";
import FooterImage from "../components/FooterImage";
import {
  checkPublicResponseAlreadyExists,
  fetchFormData,
  sendPublicFormResponse,
} from "../services/api";
import useQuery from "../hooks/useQuery";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#58555A",
    },
  },
});

const styles = {
  mobileLoader: {
    marginTop: "80%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  desktopLoader: {
    marginTop: "25%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};

const PublicForm = (props) => {
  const history = useHistory();
  const { formId, teamBotLocationId, formTitle } = useParams();
  let query = useQuery();

  let trackingId = query.get("trackingId");

  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState("");
  const [fetchedAns, setFetchedAns] = useState([]);
  const [responseAlreadyRecorded, setResponseAlreadyRecorded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pgRefresh, setPgRefresh] = useState(false);

  useEffect(() => {
    // Since this is a public form, we are not capturing who filled it.

    if (trackingId) {
      checkPublicResponseAlreadyExists(formId, trackingId).then((res) => {
        if (
          res &&
          res.code === 200 &&
          res.data !== undefined &&
          res.mssg === undefined
        ) {
          setFetchedAns(res.data);
          setResponseAlreadyRecorded(true);
          handleFormFetch();
        } else if (
          res &&
          res.code === 200 &&
          res.data === undefined &&
          res.mssg !== undefined
        ) {
          handleFormFetch();
        } else {
          history.replace("/error");
        }
      });
    } else handleFormFetch();
  }, []);

  const handlePgRefresh = () => setPgRefresh(!pgRefresh);

  const handleFormFetch = () =>
    fetchFormData(formId)
      .then((res) => {
        if (res && res.code === 200) setQuestions(res.form);
        else history.replace("/error");
      })
      .catch((err) => history.replace("/error"));

  const handleSubmit = () => {
    setLoader(true);
    let allRequiredAnswered = true;
    let allRadioGridAnswered = true;
    let allCheckBoxGridAnswered = true;

    for (const question of questions) {
      if (question.isRequired) {
        if (question.answer === undefined || !question.answer) {
          allRequiredAnswered = false;
          break;
        }

        if (question.type === "singleCorrectGrid") {
          for (const answer of question.answer) {
            if (answer === -1) {
              allRadioGridAnswered = false;
              break;
            }
          }
        }

        if (question.type === "multiCorrectGrid") {
          for (const row of question.answer) {
            let rowUnanswered = true;
            for (const [index, value] of row.entries()) {
              if (value !== -1) {
                rowUnanswered = false;
                break;
              }
            }

            if (rowUnanswered === true) {
              allCheckBoxGridAnswered = false;
              break;
            }
          }
        }
      }
    }

    if (
      !allRequiredAnswered ||
      !allRadioGridAnswered ||
      !allCheckBoxGridAnswered
    ) {
      setError("All the mandatory questions are required to be answered!");
      setLoader(false);
      return;
    }

    if (isUUID(teamBotLocationId) && formId && allRequiredAnswered) {
      let answers = [];

      for (const question of questions) {
        if (question.answer === undefined) {
          answers.push({
            answer: null,
            questionId: question.id,
            questionType: question.type,
            options: question.question.options,
          });
        } else {
          answers.push({
            answer: question.answer,
            questionId: question.id,
            questionType: question.type,
            options: question.question.options,
          });
        }
      }

      // let shortenId = nanoid();
      let completedAt = new Date();
      sendPublicFormResponse(formId, teamBotLocationId, answers, completedAt)
        .then((res) => {
          if (res && res.code === 200) {
            setLoader(false);
            history.replace("/thank-you");
          } else {
            setLoader(false);
            history.replace("/error");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          history.replace("/error");
        });
    } else history.replace("/not-found");
  };

  if (questions.length === 0) {
    return (
      <div
        style={
          device.type === "mobile" ? styles.mobileLoader : styles.desktopLoader
        }
      >
        <Loader isSmall />
        <p style={{ fontWeight: "700" }}>Loading...</p>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: "1.6em", margin: "16px 8px" }}>{decodeURIComponent(formTitle)}</h1>

        <div style={{ display: "flex", flexDirection: "row", margin: 15 }}>
          <p
            style={{ fontSize: "15px", fontWeight: "bolder", paddingRight: 5 }}
          >
            Note:
          </p>
          <p
            style={{ fontSize: "15px" }}
          >{`Questions marked with (*) are mandatory to answer.`}</p>
        </div>

        {responseAlreadyRecorded ? (
          <p
            style={{ fontSize: "15px", color: "red" }}
          >{`Response has been registered successfully.`}</p>
        ) : null}

        {questions.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {questions.map((question, index) => {
              return (
                <FormElement
                  question={question}
                  key={question.id}
                  fetchedAns={fetchedAns}
                  responseAlreadyRecorded={responseAlreadyRecorded}
                  handlePgRefresh={handlePgRefresh}
                />
              );
            })}

            {loader ? (
              <div style={{ margin: 15 }}>
                <Loader isSmall />
              </div>
            ) : (
              !responseAlreadyRecorded && (
                <Button
                  variant="contained"
                  style={{ margin: 15 }}
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )
            )}

            <FooterImage show={true} />
          </div>
        ) : null}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={error ? true : false}
          autoHideDuration={2500}
          onClose={() => setError("")}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{error}</span>}
        />
      </div>
    </ThemeProvider>
  );
};

export default PublicForm;
