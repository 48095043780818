import React from "react";

const ErrorPage = () => {
  return (
    <div className="page page--center">
      {"Some error occurred! Please try after some time."}
    </div>
  );
};

export default ErrorPage;
