const BASE_URL = process.env.REACT_APP_API;

export async function request<T = unknown>(
  input: RequestInfo,
  init?: RequestInit | undefined
) {
  const response = await fetch(input, init);

  if (response.status >= 500) {
    throw await response.json();
  }

  return (await response.json()) as T;
}

export function fetchFormData(formId: string) {
  return request(`${BASE_URL}/survey/fetch-survey-form/${formId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  });
}

export function checkResponseAlreadyExists(
  formId: string,
  conversationId: string
) {
  return request(
    `${BASE_URL}/survey/check-response-already-exists/${formId}?conversationId=${conversationId}`,
    {
      headers: { "Content-Type": "application/json" },
      method: "GET",
    }
  );
}

export function checkPublicResponseAlreadyExists(
  formId: string,
  trackingId: string
) {
  return request(
    `${BASE_URL}/survey/check-public-response-already-exists/${formId}?trackingId=${trackingId}`,
    {
      headers: { "Content-Type": "application/json" },
      method: "GET",
    }
  );
}

export function sendFormResponse(
  formId: string,
  conversationId: string,
  answers: Array<Object>,
  completedAt: Date
) {
  const body = JSON.stringify({ conversationId, answers, completedAt });
  return request(`${BASE_URL}/survey/register-form-response/${formId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  });
}

export function sendPublicFormResponse(
  formId: string,
  teamBotLocationId: string,
  answers: Array<Object>,
  completedAt: Date
) {
  const body = JSON.stringify({ answers, teamBotLocationId, completedAt });

  return request(`${BASE_URL}/survey/register-public-form-response/${formId}`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body,
  });
}
