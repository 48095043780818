import React from "react";
import { ReactComponent as EmitrrLogo } from "../assets/logo.svg";

const FooterImage = ({show}) => {

  if(show){
    return (
      <a
        className="page__branding-link"
        href="https://emitrr.com"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <div className="page__powered-by">Powered by</div>
        <EmitrrLogo className="page__branding" />
      </a>
    );
  }
  else{
    return null;
  }
};

export default FooterImage;
