const AppRoutes = {
  NOT_FOUND: "/not-found",
  ERROR_PAGE: "/error",
  THANK_YOU: "/thank-you",
  GET_FORM: (formId: string, conversationId: string, formTitle: string) =>
    `/form/${formId}/${conversationId}/${formTitle}`,
  GET_PUBLIC_FORM: "/form/:formId/:teamBotLocationId/:formTitle/public",
};

export default AppRoutes;
