import React from "react";
import cn from "classnames";

interface Props {
  isSmall?: boolean;
}

const Loader = ({ isSmall }: Props) => {
  return (
    <div className={cn("loader", { "loader--small": isSmall })}>
      <span className="loader__dot loader__dot--black" />
      <span className="loader__dot loader__dot--yellow" />
      <span className="loader__dot loader__dot--black" />
    </div>
  );
};

export default Loader;
