import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.scss";
import NotFound from "./pages/NotFound";
import ThankYou from "./pages/ThankYou";
import ErrorPage from "./pages/ErrorPage";
import Form from "./pages/Form.js";
import AppRoutes from "./constants/routes";
import PublicForm from "./pages/PublicForm";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path={AppRoutes.NOT_FOUND} exact>
          <NotFound />
        </Route>

        <Route path={AppRoutes.ERROR_PAGE} exact>
          <ErrorPage />
        </Route>

        <Route
          path={AppRoutes.GET_FORM(":formId", ":conversationId", ":formTitle")}
          exact
        >
          <Form />
        </Route>

        <Route path={AppRoutes.GET_PUBLIC_FORM} exact>
          <PublicForm />
        </Route>

        <Route path={AppRoutes.THANK_YOU} exact>
          <ThankYou />
        </Route>

        <Redirect to={AppRoutes.NOT_FOUND} />
      </Switch>
    </Router>
  );
};

export default App;
