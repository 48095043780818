import React from "react";

const ThankYou = () => {
  return (
    <div className="page page--center">
      Your response has been recorded.
      <br/>
      Thank you for giving us your precious time!
    </div>
  );
};

export default ThankYou;
