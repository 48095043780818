import React, {useState, useEffect, useCallback} from "react";
import {RadioGroup, Radio, FormControlLabel, Checkbox, Input, FormGroup, Grid} from '@material-ui/core';
import device from "current-device";

import "./style.scss";

const FormElement = ({question, responseAlreadyRecorded, fetchedAns = [], handlePgRefresh}) => {
  const [answer, setAnswer] = useState("");
  const [checkBoxOptions, setCheckboxOptions] = useState([]);
  const [nullAns, setNullAns] = useState(false);
  const [gridScrolled, setGridScrolled] = useState(false);

  useEffect(() => {
    if(fetchedAns.length > 0 && responseAlreadyRecorded){
      for(const answer of fetchedAns){
        if(answer.onlineFormQuestionId === question.id){
          if(question.type === 'multiCorrect'){
            if(answer.answer){
              let tempAns = [...answer.answer];
              let temp = {};
              for(let option of question.question.options){
                temp[option] = tempAns.includes(option);
              }
              setCheckboxOptions(temp);
            }
            else{
              setNullAns(true);
            }
          }
          else if(question.type === 'multiCorrectGrid'){
            let respondedAns = [];
            for (const row of question.onlineFormGridRows){
              let rowArr = [];
              for (const col of question.onlineFormGridColumns){
                let appendFiller = true;
                for(const ans of answer.onlineFormGridResponses){
                  if(row.id === ans.onlineFormGridRowId && col.id === ans.onlineFormGridColumnId){
                    rowArr.push(JSON.stringify({rowVal: row.id, colVal: col.id}));
                    appendFiller = false;
                    break;
                  }
                }
                if(appendFiller)
                  rowArr.push(-1);
              }
              respondedAns.push(rowArr);
            }
            setAnswer(respondedAns);
          }
          else if(question.type === 'singleCorrectGrid'){
            let respondedAns = [];
            for(const ans of answer.onlineFormGridResponses){
              respondedAns.push(JSON.stringify({rowVal: ans.onlineFormGridRowId, colVal: ans.onlineFormGridColumnId}));
            }
            setAnswer(respondedAns);
          }
          else if(question.type !== 'multiCorrectGrid' && question.type !== 'singleCorrectGrid')
            setAnswer(answer.answer);
          break;
        }
      }
    }
    if(!responseAlreadyRecorded && answer === "" && question.type === 'singleCorrectGrid'){
      let newAnswer = [];
      for (const option of question.onlineFormGridRows)
        newAnswer.push(-1);
      setAnswer(newAnswer);
    }
    if(!responseAlreadyRecorded && answer === "" && question.type === 'multiCorrectGrid'){
      let newAnswer = [];
      for (const option of question.onlineFormGridRows){
        let rowArr = [];
        for (const option of question.onlineFormGridColumns){
          rowArr.push(-1);
        }
        newAnswer.push(rowArr);
      }
      setAnswer(newAnswer);
    }
  }, []);

  const handleCheckbox = (evt, index) => {
    if(question.answer === undefined){
      question.answer = [];
    }
    if(evt.target.checked){
      question.answer.push(evt.target.value);
    }
    else if(!evt.target.checked){
      question.answer.splice(index, 1);
    }
  };

  const handleRadioGrid = (evt, Yidx) => {
    let temp = answer;
    temp[Yidx] = evt.target.value;
    setAnswer(temp);
    question.answer = answer;
    handlePgRefresh();
    // console.log(answer);
  };

  const handleCheckboxGrid = (evt, Yidx, Xidx) => {
    let tempAns = answer;
    evt.target.checked ? tempAns[Yidx][Xidx] = evt.target.value : tempAns[Yidx][Xidx] = -1;
    setAnswer(tempAns);
    question.answer = answer;
    handlePgRefresh();
    // console.log(answer);
  };

  const styles = {
    mobile: {
      display: 'flex', flexDirection: 'column', padding: 15, marginBottom: 10, border: '1px solid lightgray',
      borderRadius: '8px', maxWidth: '90%', minWidth: '90%'
    },
    desktop: {
      display: 'flex', flexDirection: 'column', padding: 15, marginBottom: 10,
      border: '1px solid lightgray', borderRadius: '8px', maxWidth: '800px', width: '100%'
    }
  };

  const handleGridScroll = useCallback((e) => {
    setGridScrolled(e.target.scrollLeft > 0);
  }, []);

  const formElementsRender = (quesType) => {
    switch(quesType){

      case 'singleChoice':
        return(
          <RadioGroup
            className="SingleChoice-RadioGroup"
            style={{display: 'flex', flexDirection: 'column'}}
            value={answer} 
            onChange={(evt) => {
              setAnswer(evt.target.value);
              question.answer = evt.target.value;
            }}
          >
            {
              question.question.options.map((option, index) => (
                <FormControlLabel
                  value={option}
                  key={index}
                  control={<Radio color="primary" disabled={responseAlreadyRecorded}/>} 
                  label={option}
                />
              ))
            }
          </RadioGroup>
        );

      case 'multiCorrect':
        if(responseAlreadyRecorded){
          return(
            <FormGroup className="MultiCorrect-FormGroup" style={{display: 'flex', flexDirection: 'column'}}>
              {
                !nullAns
                  ? Object.keys(checkBoxOptions).length !== 0 && checkBoxOptions.constructor === Object &&
                    question.question.options.map((option, index) => {
                      return (
                        <FormControlLabel
                          control={<Checkbox 
                                      color="primary" 
                                      value={option}
                                      checked={checkBoxOptions[option]}
                                      onChange={(evt) => handleCheckbox(evt, index)}
                                      disabled={responseAlreadyRecorded}
                                    />}
                          label={option}
                          key={index}
                        />
                      )})
                  : question.question.options.map((option, index) => {
                      return (
                        <FormControlLabel
                          control={<Checkbox 
                                      color="primary" 
                                      value={option}
                                      onChange={(evt) => handleCheckbox(evt, index)}
                                      disabled={responseAlreadyRecorded}
                                  />}
                          label={option}
                          key={index}
                        />
                      )})
              }
            </FormGroup>
          );
        }
        else{
          return (
            <FormGroup className="MultiCorrect-FormGroup" style={{display: 'flex', flexDirection: 'column'}}>
              {
                question.question.options.map((option, index) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox 
                                  color="primary" 
                                  value={option}
                                  onChange={(evt) => handleCheckbox(evt, index)}
                                  disabled={responseAlreadyRecorded}
                              />}
                      label={option}
                      key={index}
                    />
                  )})
              }
            </FormGroup>
          );
        }

      case 'userTyped':
        return (
          <div className="UserTyped-Container" style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{fontSize: '15px', paddingRight: 10, fontWeight: '700'}}>Ans:</p>
              <Input
                value={answer ? answer : ''}
                disabled={responseAlreadyRecorded}
                color='primary'
                onChange={(evt) => {
                  setAnswer(evt.target.value);
                  question.answer = evt.target.value;
                }}
                style={{display: 'flex', flexDirection: 'row', width: '90%'}}
                multiline
                placeholder="Type answer here"
              />
          </div>
        );

      case 'linearScale':
        const ratingScale = ['1', '2', '3', '4', '5'];
        return(
          <RadioGroup
            className="LinearScale-RadioGroup"
            style={{display: 'flex', flexDirection: 'column'}}
            value={answer} 
            onChange={(evt) => {
              setAnswer(evt.target.value);
              question.answer = evt.target.value;
            }}
          >
            {
              ratingScale.map((option, index) => (
                <FormControlLabel
                  value={option}
                  key={index}
                  control={<Radio color="primary" disabled={responseAlreadyRecorded}/>} 
                  label={index === 0 
                    ? option + ' (' + question.question.options[0] + ')'
                    : index === (ratingScale.length - 1)
                      ? option + ' (' + question.question.options[1] + ')'
                      : option
                  }
                  labelPlacement='bottom'
                />
              ))
            }
          </RadioGroup>
        );
          
      case 'singleCorrectGrid':
        let gridXaxis = question.onlineFormGridColumns.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
        let gridYaxis = question.onlineFormGridRows.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));

        let totalGrids = parseInt(gridXaxis.length + 1, 10);
        let gridSize = Math.floor(12/totalGrids);
        return (
          <div
            className={`SingleCorrectGrid-Container ${
              gridScrolled ? "Scrolled" : ""
            }`}
            onScroll={handleGridScroll}
          >
            <div className="SingleCorrectGrid-Inner">
              <Grid container spacing={1} style={{ textAlign: "center" }}>
                <Grid item xs={gridSize} />
                {gridXaxis.map((Xoption, Xidx) => (
                  <Grid
                    item
                    xs={gridSize}
                    style={{ textAlign: "center" }}
                    key={Xidx}
                  >
                    <p style={{ fontSize: 13 }}>
                      {Xoption.name}
                    </p>
                  </Grid>
                ))}
              </Grid>
              {gridYaxis.map((Yoption, Yidx) => (
                <Grid container spacing={1} key={Yidx}>
                  <Grid item xs={gridSize} style={{ textAlign: "left" }}>
                    <p style={{ fontSize: 13 }}>
                      {Yoption.name}
                    </p>
                  </Grid>
                  {gridXaxis.map((Xoption, Xidx) => (
                    <Grid
                      item
                      xs={gridSize}
                      style={{ textAlign: "center" }}
                      key={Xidx}
                    >
                      <Radio
                        key={Xidx}
                        color="primary"
                        checked={
                          answer[Yidx] ===
                          JSON.stringify({
                            rowVal: Yoption.id,
                            colVal: Xoption.id,
                          })
                        }
                        onChange={(evt) => handleRadioGrid(evt, Yidx)}
                        value={JSON.stringify({
                          rowVal: Yoption.id,
                          colVal: Xoption.id,
                        })}
                        disabled={responseAlreadyRecorded}
                      />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </div>
          </div>
        );

      case 'multiCorrectGrid':
        let Xaxis = question.onlineFormGridColumns.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
        let Yaxis = question.onlineFormGridRows.sort((a,b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
        let totalCheckboxGrids = parseInt(Xaxis.length + 1, 10);

        let checkBoxGridSize = Math.floor(12/totalCheckboxGrids);
        return (
          <div
            className={`MultiCorrectGrid-Container ${
              gridScrolled ? "Scrolled" : ""
            }`}
            onScroll={handleGridScroll}
          >
            <div className="MultiCorrectGrid-Inner">
              <Grid container spacing={1} style={{ textAlign: "center" }}>
                <Grid item xs={checkBoxGridSize} />
                {Xaxis.map((Xoption, Xidx) => (
                  <Grid
                    item
                    xs={checkBoxGridSize}
                    style={{ textAlign: "center" }}
                    key={Xidx}
                  >
                    <p style={{ fontSize: 13 }}>
                      {Xoption.name}
                    </p>
                  </Grid>
                ))}
              </Grid>
              {Yaxis.map((Yoption, Yidx) => (
                <Grid container spacing={1} key={Yidx}>
                  <Grid
                    item
                    xs={checkBoxGridSize}
                    style={{ textAlign: "left" }}
                  >
                    <p style={{ fontSize: 13 }}>
                      {Yoption.name}
                    </p>
                  </Grid>
                  {Xaxis.map((Xoption, Xidx) => (
                    <Grid
                      item
                      xs={checkBoxGridSize}
                      style={{ textAlign: "center" }}
                      key={Xidx}
                    >
                      <Checkbox
                        key={Xidx}
                        color="primary"
                        checked={
                          answer &&
                          answer[Yidx] &&
                          answer[Yidx][Xidx] ===
                            JSON.stringify({
                              rowVal: Yoption.id,
                              colVal: Xoption.id,
                            })
                            ? true
                            : false
                        }
                        onChange={(evt) => handleCheckboxGrid(evt, Yidx, Xidx)}
                        value={JSON.stringify({
                          rowVal: Yoption.id,
                          colVal: Xoption.id,
                        })}
                        disabled={responseAlreadyRecorded}
                      />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </div>
          </div>
        );
      
      default: 
        return null;
    };
  };

  return (
    <div style={device.type === "mobile" ? styles.mobile : styles.desktop}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <p style={{fontSize: '15px', paddingRight: 5, fontWeight: '700'}}>Ques:</p>
        <p style={{fontSize: '15px', paddingRight: 5}}>{question.question.question}</p>
        {
          question.isRequired
          ? <p style={{fontSize: '15px', color: 'red', fontWeight: 'bolder'}}>*</p>
          : null
        }
      </div>
      {
        formElementsRender(question.type)
      }
    </div>
  );
};

export default FormElement;
